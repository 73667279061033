export const EURLogo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 1599.000000 1066.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,1066.000000) scale(0.100000,-0.100000)"
      fill="#039"
      stroke="none"
    >
      <path
        d="M0 5330 l0 -5330 7995 0 7995 0 0 5330 0 5330 -7995 0 -7995 0 0
  -5330z m8338 3740 c117 0 211 -4 208 -9 -4 -6 -254 -190 -328 -242 -4 -3 21
  -95 56 -204 36 -110 64 -200 63 -202 -1 -1 -79 54 -172 122 -96 69 -174 120
  -180 115 -52 -40 -329 -239 -331 -237 -2 1 26 92 61 202 36 109 64 199 62 200
  -1 2 -78 58 -172 126 l-170 124 215 3 215 3 65 200 65 201 65 -201 65 -200
  213 -1z m-2049 -287 l62 -193 216 0 215 0 -170 -122 c-94 -68 -171 -130 -171
  -138 0 -8 26 -98 59 -199 33 -102 58 -186 56 -188 -2 -2 -79 50 -170 117
  l-166 122 -116 -84 c-63 -45 -141 -102 -174 -126 l-59 -42 14 42 c89 273 115
  359 111 367 -3 5 -79 62 -170 127 l-164 119 213 3 212 2 64 200 c35 110 66
  196 70 192 3 -4 34 -94 68 -199z m3827 -195 l213 -3 -165 -119 c-90 -65 -167
  -122 -170 -127 -4 -8 22 -94 111 -367 l14 -42 -59 42 c-33 24 -111 80 -174
  126 l-115 83 -166 -121 c-92 -67 -169 -120 -171 -118 -2 3 23 87 56 189 33
  101 60 191 60 199 -1 8 -29 35 -63 59 -34 25 -111 80 -170 123 l-109 78 216 0
  215 0 62 193 c34 105 64 196 67 202 3 5 35 -82 71 -193 l64 -202 213 -2z
  m-5129 -1102 l64 -196 216 0 216 0 -175 -126 -175 -127 63 -196 c35 -108 64
  -200 64 -205 0 -5 -10 0 -22 11 -13 11 -90 68 -171 127 l-148 106 -172 -124
  c-95 -68 -173 -123 -174 -122 -1 0 27 89 63 196 35 107 62 200 60 205 -2 6
  -78 64 -170 130 l-166 120 213 3 214 2 63 200 c35 110 65 198 68 196 2 -3 33
  -93 69 -200z m6430 -198 l213 -3 -170 -123 c-93 -68 -170 -126 -170 -129 0 -6
  114 -356 126 -387 5 -14 -10 -7 -54 25 -34 24 -113 81 -176 127 l-115 83 -170
  -125 c-94 -69 -171 -122 -171 -118 0 4 29 95 64 204 l63 196 -61 44 c-33 25
  -112 81 -174 126 l-114 82 215 0 216 0 61 188 c34 103 64 194 67 202 3 8 35
  -76 71 -187 l66 -203 213 -2z m-6632 -1778 l211 -5 -168 -120 c-92 -66 -168
  -125 -168 -130 0 -6 29 -97 64 -203 36 -106 62 -192 59 -192 -3 0 -77 52 -163
  115 -87 63 -164 117 -172 120 -7 2 -87 -49 -178 -115 -91 -67 -166 -117 -167
  -113 -2 5 24 89 57 188 33 99 60 187 60 195 0 9 -71 67 -165 136 l-165 119
  209 3 210 2 57 177 c31 97 61 188 66 202 10 23 18 4 77 -174 l66 -200 210 -5z
  m6835 193 l61 -193 210 -2 211 -3 -166 -120 c-91 -66 -166 -127 -166 -135 0
  -9 27 -96 60 -195 33 -99 59 -183 58 -187 -2 -5 -77 46 -168 112 -91 66 -171
  118 -178 115 -8 -3 -85 -57 -172 -120 -86 -63 -160 -115 -163 -115 -3 0 23 86
  59 192 35 106 64 197 64 203 0 5 -76 64 -167 130 l-167 120 210 5 209 5 64
  198 c36 108 68 194 72 190 4 -5 35 -95 69 -200z m-6635 -1765 l63 -193 211 -3
  c116 -1 211 -6 210 -10 0 -4 -74 -60 -165 -125 -90 -65 -164 -125 -164 -134 0
  -8 27 -98 61 -200 33 -101 59 -186 57 -188 -1 -2 -76 50 -166 116 -89 65 -167
  119 -173 119 -6 0 -84 -53 -172 -117 -89 -65 -164 -119 -168 -121 -4 -2 22 86
  57 195 35 109 64 200 64 204 0 3 -77 61 -172 130 l-172 124 215 3 215 2 65
  199 c35 110 66 197 68 196 1 -2 31 -91 66 -197z m6434 -200 l215 -3 -172 -124
  c-95 -69 -172 -127 -172 -130 0 -4 29 -95 64 -203 35 -109 58 -195 52 -191 -6
  4 -83 59 -172 124 l-161 116 -169 -122 c-93 -68 -171 -122 -172 -120 -2 2 24
  87 57 188 34 102 61 192 61 201 0 8 -72 67 -164 133 -91 65 -165 121 -165 125
  -1 4 94 9 210 10 l211 3 65 200 65 199 66 -202 66 -202 215 -2z m-5133 -1100
  l63 -193 216 -5 217 -5 -171 -124 c-94 -68 -171 -129 -171 -137 0 -7 27 -97
  61 -200 33 -102 59 -187 57 -189 -2 -2 -76 50 -166 116 -89 65 -167 119 -173
  119 -6 0 -85 -54 -175 -120 -91 -66 -166 -120 -168 -120 -2 0 25 88 60 196 35
  108 64 199 64 203 0 4 -77 63 -172 132 l-172 124 215 3 215 2 62 189 c34 103
  62 194 62 201 0 31 16 -10 76 -192z m3604 -160 l11 -38 217 -2 217 -3 -172
  -125 c-95 -68 -173 -127 -173 -131 0 -4 29 -95 64 -203 35 -108 62 -196 60
  -196 -2 0 -77 54 -168 120 -90 66 -168 120 -174 120 -5 0 -83 -54 -173 -119
  -90 -66 -165 -118 -167 -116 -2 2 24 87 57 190 34 103 61 194 61 201 0 7 -77
  68 -170 136 l-170 123 216 5 215 5 64 195 c36 107 65 197 65 200 1 6 107 -313
  120 -362z m-1825 -328 l61 -185 213 -5 213 -5 -153 -112 c-85 -61 -162 -118
  -171 -125 -16 -12 -13 -27 47 -212 35 -110 63 -201 62 -202 -1 0 -72 50 -157
  113 -85 62 -162 118 -171 123 -13 7 -55 -19 -185 -114 -93 -68 -170 -122 -171
  -122 -1 1 27 92 62 202 36 109 65 200 65 200 0 1 -77 57 -171 125 l-171 124
  213 5 213 5 65 200 c35 110 67 193 70 185 3 -8 33 -98 66 -200z"
      />
    </g>
  </svg>
);
