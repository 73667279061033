import { TwoFactorTypesEnum } from '../types/user.interface';
import GoogleIcon from '@mui/icons-material/Google';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';

const getIconLoginTypeHelper = (loginType: string) => {
  if (TwoFactorTypesEnum.GOOGLE_AUTHENTICATOR === loginType) {
    return <GoogleIcon />;
  } else if (TwoFactorTypesEnum.EMAIL === loginType) {
    return <EmailIcon />;
  } else {
    return <SmsIcon />;
  }
};

export { getIconLoginTypeHelper };
