import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const getIconVerified = (verified: boolean | undefined) => {
  if (verified) {
    return <CheckCircleIcon color="success" />;
  } else {
    return <CancelIcon color="error" />;
  }
};

export { getIconVerified };
