import { useState, useEffect } from 'react';
import { secondsTransformHelper } from '../helpers/secondsTransformHelper';
import { TwoFactorTypesEnum } from '../types/user.interface';

type Props = {
  initialTime: number;
  type: string;
};

export const useVerificationCodeTimer = ({
  initialTime,
  type,
}: Props): [string, () => void, boolean] => {
  const [time, setTime] = useState(initialTime);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const isEmailType = type === TwoFactorTypesEnum.EMAIL;

  const storageStartTimeName = isEmailType ? 'startTimeEmail' : 'startTimeSms';
  const storageTimeName = isEmailType ? 'timeEmail' : 'timeSms';
  const storageIsRunningName = isEmailType ? 'isRunningEmail' : 'isRunningSms';

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    const tick = () => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          setIsTimerRunning(false);
          clearInterval(timerId);
          return initialTime;
        }
        return prevTime - 1;
      });
    };

    if (isTimerRunning) {
      timerId = setInterval(tick, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [isTimerRunning]);

  useEffect(() => {
    const storedStartTime = localStorage.getItem(storageStartTimeName);
    const storedTime = localStorage.getItem(storageTimeName);
    const storedIsRunning = localStorage.getItem(storageIsRunningName);

    if (storedStartTime && storedTime && storedIsRunning) {
      const parsedStartTime = parseInt(storedStartTime, 10);
      const parsedTime = parseInt(storedTime, 10);
      const parsedIsRunning = JSON.parse(storedIsRunning);

      if (
        !isNaN(parsedStartTime) &&
        !isNaN(parsedTime) &&
        typeof parsedIsRunning === 'boolean'
      ) {
        const currentTime = Date.now();
        const elapsedTime = Math.floor((currentTime - parsedStartTime) / 1000);
        const remainingTime = parsedTime - elapsedTime;

        if (remainingTime > 0) {
          setTime(remainingTime);
          setIsTimerRunning(parsedIsRunning);
        } else {
          setTime(0);
          setIsTimerRunning(false);
          localStorage.removeItem(storageStartTimeName);
          localStorage.removeItem(storageTimeName);
          localStorage.removeItem(storageIsRunningName);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isTimerRunning) {
      const currentTime = Date.now();
      localStorage.setItem(storageStartTimeName, currentTime.toString());
    } else {
      localStorage.removeItem(storageStartTimeName);
    }
    localStorage.setItem(storageTimeName, time.toString());
    localStorage.setItem(storageIsRunningName, JSON.stringify(isTimerRunning));
  }, [time, isTimerRunning]);

  const startTimer = () => {
    setIsTimerRunning(true);
  };

  return [secondsTransformHelper(time), startTimer, isTimerRunning];
};
