import {
  KycInterface,
  KycResetInterface,
} from '../types/user.profile.interface';
import { baseApi } from './base.api';

export const kycService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    kycToken: build.query<KycInterface, {}>({
      query: () => ({
        url: '/user/kyc/token',
      }),
    }),
    resetKycVerification: build.mutation<KycResetInterface, any>({
      query: () => ({
        url: '/user/kyc/reset',
        method: 'POST',
      }),
    }),
  }),
});

export const { useKycTokenQuery, useResetKycVerificationMutation } = kycService;
