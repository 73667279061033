import { PATH_REVIEW_API } from '../constants/api-routes';
import {
  ReviewCreateParamsInterface,
  ReviewMyInterface,
} from '../types/review.interface';
import { baseApi } from './base.api';

export const reviewService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMyReview: build.query<ReviewMyInterface, {}>({
      query: () => ({
        url: `${PATH_REVIEW_API.MY}`,
      }),
    }),
    getUserReviewById: build.query<ReviewMyInterface[], { userId: number }>({
      query: ({ userId }) => ({
        url: `${PATH_REVIEW_API.USER}/${userId}`,
      }),
    }),
    createReview: build.mutation<
      ReviewMyInterface,
      ReviewCreateParamsInterface
    >({
      query: (params) => ({
        url: `${PATH_REVIEW_API.ROOT}`,
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const {
  useCreateReviewMutation,
  useGetMyReviewQuery,
  useGetUserReviewByIdQuery,
} = reviewService;
