import { Middleware } from '@reduxjs/toolkit';
import { PATH_AUTH_API } from 'constants/api-routes';

export const unauthorizedMiddleware: Middleware = () => (next) => (action) => {
  if (
    action?.payload?.status === 401 &&
    action?.payload?.data?.message === 'Unauthorized'
  ) {
    localStorage.removeItem('token');
    window.location.replace(PATH_AUTH_API.ROOT);
  }

  return next(action);
};
