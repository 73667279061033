import { FC, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import { useWeb3Modal } from '@web3modal/react';
import { Dialog, DialogContent, Button, DialogTitle } from '@mui/material';

type NotConnectedModalProps = {
  open: boolean;
  onClose: () => void;
};

export const NotConnectedModal: FC<NotConnectedModalProps> = ({
  open,
  onClose,
}) => {
  const { open: openWeb3Modal } = useWeb3Modal();

  const onConnectWallet = useCallback(() => {
    openWeb3Modal();
    onClose();
  }, [openWeb3Modal, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Wallet Is Not Connected</DialogTitle>
      <DialogContent>
        <Typography
          id="modal-modal-description"
          sx={{ marginTop: 2, marginBottom: 2 }}
        >
          Please connect you wallet to allow exchanges
        </Typography>
        <Button onClick={onConnectWallet} variant="contained" color="primary">
          Connect Web3 Wallet
        </Button>
      </DialogContent>
    </Dialog>
  );
};
