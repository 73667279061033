import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'locales/en.json';

const getDefLang = (): string => {
  const stored = localStorage.getItem('language');

  if (stored) return stored;

  if (
    window?.navigator?.language === 'en' ||
    window?.navigator?.language === 'ru'
  )
    return window?.navigator?.language;

  return 'en';
};

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    lng: getDefLang(),
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    resources: {
      en: { translation: en },
    },
  })
  .then();

export default i18n;
