import { FC } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import styled from '@emotion/styled';

const QrCodeContainer = styled('div')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);

  canvas {
    width: 100%;
  }
`;

type QrCodeProps = {
  url: string;
};

export const QrCode: FC<QrCodeProps> = ({ url }) => {
  return (
    <QrCodeContainer>
      <QRCodeCanvas
        id="qrCode"
        value={url}
        size={200}
        bgColor={'#fff'}
        level={'H'}
      />
    </QrCodeContainer>
  );
};
