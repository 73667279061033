import { useEffect, useCallback } from 'react';

type UseDebounceProps = {
  effect: () => void;
  dependencies: any[];
  delay: number;
};

export const useDebounce = ({
  effect,
  dependencies,
  delay,
}: UseDebounceProps): void => {
  const callback = useCallback(effect, dependencies);
  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
};
