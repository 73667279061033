import { FC, memo, useEffect } from 'react';
import { Dialog } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import { useGetUserReviewByIdQuery } from '../../../services';
import { ReviewItem } from './review.item';
import { ReviewMyInterface } from '../../../types/review.interface';
import { Loader } from '../../../shared';

type ReviewModalProps = {
  open: boolean;
  onClose: () => void;
};

const ADMIN_ID = Number(process.env.REACT_APP_ADMIN_ID) || 13;

export const ReviewListModal: FC<ReviewModalProps> = memo(
  ({ open, onClose }) => {
    const {
      data: userReviewData,
      isFetching: userDataIsFetching,
      refetch: refetchUserData,
    } = useGetUserReviewByIdQuery({ userId: ADMIN_ID });

    useEffect(() => {
      if (open) {
        refetchUserData();
      }
    }, [open]);
    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <Box sx={{ overflow: 'auto' }}>
          <CardHeader sx={{ pb: 0 }} title="Feedbacks" />
          {userDataIsFetching ? (
            <Loader pad={20} />
          ) : (
            userReviewData &&
            userReviewData?.map((el: ReviewMyInterface) => (
              <ReviewItem key={el.id} item={el} />
            ))
          )}
        </Box>
      </Dialog>
    );
  }
);
