import { FC, memo } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Rating from '@mui/material/Rating';
import { ReviewMyInterface } from '../../../types/review.interface';

type ReviewItemProps = {
  item: ReviewMyInterface;
};

export const ReviewItem: FC<ReviewItemProps> = memo(({ item }) => {
  const date = new Date(item?.createdAt);

  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
  return (
    <Card sx={{ minWidth: 275 }}>
      <Box component="form" noValidate>
        <CardContent>
          <Typography sx={{ fontSize: 20, mb: 0 }} gutterBottom>
            {item?.Reviewer?.email}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {formattedDate}
          </Typography>
          <Rating readOnly name="simple-controlled" value={item?.rating} />
          <Typography sx={{ mb: 0.5, width: '100%' }} color="text.secondary">
            {item?.comment}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
});
